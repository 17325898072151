import React from 'react';
import logo from './assets/images/new-logo.svg'
import './App.css';

function App() {
  return (
    <div className="gx-app">
      <header className="gx-app-header">
        <img src={logo} className="gx-app-logo" alt="logo" data-testid="ALTEON_LOGO" />
      </header>
    </div>
  );
}

export default App;
